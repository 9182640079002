/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getSuppliers } from '../fn/supplier/get-suppliers';
import { GetSuppliers$Params } from '../fn/supplier/get-suppliers';
import { patchSupplier } from '../fn/supplier/patch-supplier';
import { PatchSupplier$Params } from '../fn/supplier/patch-supplier';
import { Supplier } from '../models/supplier';

@Injectable({ providedIn: 'root' })
export class SupplierApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getSuppliers()` */
  static readonly GetSuppliersPath = '/suppliers';

  /**
   * Retrieve the available suppliers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSuppliers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSuppliers$Response(params?: GetSuppliers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Supplier>>> {
    return getSuppliers(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieve the available suppliers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSuppliers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSuppliers(params?: GetSuppliers$Params, context?: HttpContext): Observable<Array<Supplier>> {
    return this.getSuppliers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Supplier>>): Array<Supplier> => r.body)
    );
  }

  /** Path part for operation `patchSupplier()` */
  static readonly PatchSupplierPath = '/suppliers/{bobId}';

  /**
   * Patch a Supplier Entity.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchSupplier()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchSupplier$Response(params: PatchSupplier$Params, context?: HttpContext): Observable<StrictHttpResponse<Supplier>> {
    return patchSupplier(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch a Supplier Entity.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchSupplier$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchSupplier(params: PatchSupplier$Params, context?: HttpContext): Observable<Supplier> {
    return this.patchSupplier$Response(params, context).pipe(
      map((r: StrictHttpResponse<Supplier>): Supplier => r.body)
    );
  }

}
